$c-primary: #494949;
$c-secandary: #0072ac;
$dots: false;
$body-color-overrided: #494949;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'MyRiadPro', sans-serif;
$font-family-heading: 'MyRiadPro', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.quickAddButtonGroup {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;

    .quickAddButton {
        margin-right: 0.5rem;
    }
}