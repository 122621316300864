$c-primary: #494949;
$c-secandary: #0072ac;
$dots: false;
$body-color-overrided: #494949;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'MyRiadPro', sans-serif;
$font-family-heading: 'MyRiadPro', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  text-align: left;

  .description {
    text-align: left;
    margin-bottom: 1.5rem; //24
  }
}
