$c-primary: #494949;
$c-secandary: #0072ac;
$dots: false;
$body-color-overrided: #494949;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'MyRiadPro', sans-serif;
$font-family-heading: 'MyRiadPro', sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  font-size: 1rem;
  line-height: 1.25;
  font-family: var(--base-font-family);

  .description {
    text-align: left;
  }
}
